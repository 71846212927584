// i18next-extract-mark-ns-start capabilities-emissions-combustion

import { LINKS, EMISSIONS_NAV, CAPABILITIES_PRODUCTS_FLUID_DYNAMICS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import ResourceListing from '@components/views/ResourceListing';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesCombustionEmissionsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const wavert = getImage(data.wavert.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							Cutting emissions from combustion has driven developments in hybrid engines and catalytic converters,
							however the heart of combustion is the fuel that is being burnt. E-fuels and hydrogen are now being used as fossil fuel
							replacements in Internal Combustion Engines (ICE) but require design modification and fuel performance modelling to assess viability,
							dependent on the application. This is best achieved in a virtual environment to understand the ‘What ifs’ before moving to physical testing.
						</p>
						<p>
							For example, Hydrogen has been identified as a promising decarbonization fuel for ICE applications
							including heavy-duty, on- and off-road, rail, power-generation and marine.
							Whilst Hydrogen ICEs can achieve high power density and extremely low tailpipe emissions,
							there are challenges in designing systems for a gaseous fuel with its own specific mixing, burn rate and combustion control needs.
							The main pollutant of concern for Hydrogen ICEs is NOx, which can be addressed by running the engine at
							lean equivalence ratios and using Exhaust Gas Recirculation (EGR).
						</p>
						<p>
							Our 1D and 3D Computational Fluid Dynamics (CFD) simulation toolsets can quickly and accurately provide answers to the ‘What ifs’ which are vital
							to the overall assessment of emissions from different fuel types and different fuel blends.
						</p>
					</Trans>

					{wavert && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wavert}
								alt="WAVE-RT"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							WAVE-RT is a 1D CFD tool which provides real-time emissions analysis
							as well as the ability to model unburned hydrocarbons which are key components in IC engine emissions.
							Predictive sub-models include NOx and Hydrocarbons emissions.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="WAVE-RT"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							VECTIS is a 3D CFD tool for modelling emissions for conventional hydrocarbons, e-fuels and synthetic fuels.
							Lean hydrogen flame speed modelling provides robust models to simulate emissions from engines running on hydrogen.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CuttingEmissions")}
						className="widget__sibling__pages">
						<AssetList>
							{EMISSIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_FLUID_DYNAMICS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default CapabilitiesCombustionEmissionsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-emissions-combustion", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/emissions/Realis-Simulation_1325_motorcycleExhaust_cropped.jpg" }) {
		...imageBreaker
	}
	wavert: file(relativePath: { eq: "content/logos/WAVERT.png" }) {
		...imageBreaker
	}
	vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { tags: { in: "Emissions" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
